(function($){

	$(document).ready(function() {

		/* ---------------------------------------------- /*
		 * Contact form ajax
		 /* ---------------------------------------------- */

		$('#contact-form').find('input,textarea').jqBootstrapValidation({
			preventSubmit: true,
			submitError: function($form, event, errors) {

				// additional error messages or events
			},
			submitSuccess: function($form, event) {
				event.preventDefault();

				var submit          = $('#contact-form submit');
				var ajaxResponse    = $('#contact-response');

				var name            = $('#contact-form [name="name"]').val();
				var email           = $('#contact-form [name="email"]').val();
				var message         = $('#contact-form [name="message"]').val();

				$.ajax({
					type: 'POST',
					url: '/cms/ajax/contact.php',
					dataType: 'json',
					data: {
						name: name,
						email: email,
						message: message,
					},
					cache: false,
					beforeSend: function(result) {
						submit.empty();
						submit.append('<i class="fa fa-cog fa-spin"></i>');
					},
					success: function(result) {
						if(result.sendstatus == 1) {
							ajaxResponse.html(result.message);
							$form.fadeOut(500);
						} else {
							ajaxResponse.html(result.message);
						}
					}
				});
			}
		});

		$('#download-form').find('input').jqBootstrapValidation({
			preventSubmit: true,
			submitError: function($form, event, errors) {

				// additional error messages or events
			},
			submitSuccess: function($form, event) {
				event.preventDefault();

				var submit          = $('#download-form submit');
				var ajaxResponse    = $('#download-response');

				var name            = $('#download-form [name="name"]').val();
				var email           = $('#download-form [name="email"]').val();

				$.ajax({
					type: 'POST',
					url: '/cms/ajax/download.php',
					dataType: 'json',
					data: {
						name: name,
						email: email
					},
					cache: false,
					beforeSend: function(result) {
						submit.empty();
						submit.append('<i class="fa fa-cog fa-spin"></i>');
					},
					success: function(result) {
						if(result.sendstatus == 1) {
							ajaxResponse.html(result.message);
							$form.fadeOut(500);
						} else {
							ajaxResponse.html(result.message);
						}
					}
				});
			}
		});

	});

})(jQuery);